import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import {useState} from "react";
import Fade from 'react-reveal/Fade';
function App() {



    const AmaraDepok = function (){
        return (
            <div className={"flex flex-col lg:w-1/3 items-center  justify-center my-8 lg:my-0"}>
                <Fade right delay={200}>
            <p className={"text-yellow-600 text-3xl font-nuntio"}>West Java</p>
                </Fade>
                <Fade right delay={600}>
                    <p className={"text-black text-4xl mt-2 text-white"}>Depok</p>
                </Fade>

            </div>
        )
    }

    const Mandiri = function(){
        return (
            <div className={"flex flex-col lg:w-1/3 items-center  justify-center my-8 lg:my-0"}>
                <Fade right delay={200}>
                    <p className={"text-yellow-600 text-3xl font-nuntio"}>South Jakarta</p>
                </Fade>
                <Fade right delay={600}>
                    <p className={"text-black text-4xl mt-2 text-white"}>Jakarta</p>
                </Fade>

            </div>
        )
    }

    const NtTower = function (){
        return (
            <div className={"flex flex-col lg:w-1/3 items-center  justify-center my-8 lg:my-0"}>
                <Fade right delay={200}>
                    <p className={"text-yellow-600 text-3xl font-nuntio"}>East Jakarta</p>
                </Fade>
              <Fade right delay={600}>
                  <p className={"text-black text-4xl mt-2 text-white"}>Jakarta</p>
              </Fade>

            </div>
        )
    }


    const [Component,setComponent]　= useState(<AmaraDepok/>)
    const Components = [
        <AmaraDepok/>,<Mandiri/>,<NtTower/>
    ]
  return (
    <div className="h-screen bg-black flex flex-col ">
      <div className={"flex flex-row justify-between p-8"}>
          <div className={"flex flex-row"}>
              <p className={" text-2xl font-nuntio text-yellow-600"}>Studio T</p>
              <p className={" text-2xl font-nuntio text-white ml-2"}>Transintrasia</p>
          </div>

        <div className={"hidden lg:block"}>
            <a href={"http://srv115.niagahoster.com:2095"} className={"text-xl text-white"}>Webmail</a>
        </div>
      </div>
        <div className={"flex flex-col lg:flex-row    "}>
            <div className={"     h-auto w-auto lg:w-2/3"}>
                <Carousel
                    onChange={(index,item)=>{
                       setComponent(Components[index])
                    }}
                    interval={5000}
                    showIndicators={false} autoPlay={true} infiniteLoop={true} showThumbs={false} showStatus={false} dynamicHeight={true} width={"100%"}>
                    <img src={"/kost.webp"}/>
                    <img src={"/mandiri.webp"}/>
                    <img src={"/ntTower.webp"}/>
                </Carousel>
            </div>
            {Component?Component:<div></div>}

        </div>
        <div className={"flex flex-col lg:flex-row w-full bg-white  "}>
            <div className={"lg:w-1/2 lg:rounded-lg lg:shadow-lg lg:m-8  bg-gray-100 "}>
                <div className={"p-4 lg:p-0 lg:m-16"}>
                    <p className={"font-nuntio text-2xl lg:text-4xl font-light text-yellow-600 "}>About Us</p>
                    <p className={"font-nuntio text-xl text-wrap lg:text-2xl mt-8"}>We believe good design grows from various factors but the most fundamental is effective communication with clients.</p>

                    <p className={"font-nuntio text-2xl lg:text-4xl font-light mt-16 text-yellow-600"}>Vision</p>
                    <p className={"font-nuntio text-xl lg:text-2xl mt-8"}>Become on of the most Trusted Architecture and Interior Design Consultant</p>

                    <p className={"font-nuntio text-2xl lg:text-4xl font-light mt-16 text-yellow-600 "}>Mission</p>


                    <p className={"font-nuntio text-xl lg:text-2xl  mt-8 text-justify"}>
                       We organize our service system in such away that every project can be controlled and reviewed
                        efficiently in order to achieve a consistent standard of performance.
                    </p>
                    <p className={"font-nuntio text-xl lg:text-2xl mt-4 text-justify"}>
                        Synergize with young generation who are bound by the same commitment to maintain quality and service
                        to clients.
                    </p>

                </div>
               </div>

                <div className={"mt-8 mb-8 lg:mb-0 lg:mt-0 lg:w-1/2 flex flex-col items-center justify-center mx-auto "}>

                        <p className={"text-4xl lg:text-8xl font-nuntio text-yellow-600"}>17 Years</p>
                        <p className={"text-2xl lg:text-6xl font-nuntio text-yellow-600"}>Experiences</p>


                </div>





        </div>
    </div>
  );
}

export default App;
